import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { ChangePasswordComponent } from './pages/changePassword/changePassword.component';
export const routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: '/dashboard/v3', pathMatch: 'full' },
            { path: 'home', loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule' },
            { path: 'budget', loadChildren: './budget/budget.module#BudgetModule' },
            { path: 'corrispettivi', loadChildren: './corrispettivi/corrispettivi.module#CorrispettiviModule' },
            { path: 'librocassa', loadChildren: './librocassa/librocassa.module#LibrocassaModule' },
            { path: 'manutenzioni', loadChildren: './manutenzioni/manutenzioni.module#ManutenzioniModule' },
            { path: 'elements', loadChildren: './elements/elements.module#ElementsModule' },
            { path: 'forms', loadChildren: './forms/forms.module#FormsModule' },
            { path: 'charts', loadChildren: './charts/charts.module#ChartsModule' },
            { path: 'tables', loadChildren: './tables/tables.module#TablesModule' },
            { path: 'maps', loadChildren: './maps/maps.module#MapsModule' },
            { path: 'blog', loadChildren: './blog/blog.module#BlogModule' },
            { path: 'ecommerce', loadChildren: './ecommerce/ecommerce.module#EcommerceModule' },
            { path: 'anagrafiche', loadChildren: './anagrafiche/anagrafiche.module#AnagraficheModule' },
            { path: 'operativita', loadChildren: './operativita/operativita.module#OperativitaModule' },
            { path: 'businessIntelligence', loadChildren: './businessIntelligence/businessIntelligence.module#BusinessIntelligenceModule' },
            { path: 'dataImport', loadChildren: './dataImport/dataImport.module#DataImportModule' },
            { path: 'extras', loadChildren: './extras/extras.module#ExtrasModule' },
            // Api Demo
            { path: 'api', loadChildren: './api/api.module#ApiModule' }
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'changePassword', component: ChangePasswordComponent },
	{ path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LoginComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    //{ path: '**', redirectTo: 'home' }
    { path: '**', component: LoginComponent }

];
