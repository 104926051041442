import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { fbind } from 'q';
import { Token } from '../../../model/token';
import { TrendAppApiService } from '../../../services/trend-app-api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


    valForm: FormGroup;
    id: number;

    constructor(private http: HttpClient, private trendAppApiService: TrendAppApiService, public settings: SettingsService, fb: FormBuilder, private router: Router) {
        localStorage.setItem('Logged', 'false');
        this.valForm = fb.group({
            login: [null, Validators.compose([Validators.required])],
            password: [null, Validators.required]
        });

    }
    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {

            const token: Token = {
                login: this.valForm.controls.login.value,
                password: this.valForm.controls.password.value,
                id: 0,
                nome: '',
                cognome: '',
                date: new Date(),
                ruolo: '',
                mytoken: ''
            };



            this.id = 0;
            // ,
            // this.id = result.id

            this.trendAppApiService.getToken(token)
                .subscribe(result => {
                    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.valForm.value, null, 4));

                    if (result.id > 0) {
                        localStorage.setItem('UserId', result.id.toString());
                        localStorage.setItem('Logged', 'true');
                        localStorage.setItem('Ruolo', result.ruolo);
                        localStorage.setItem('Nome', result.nome);
                        localStorage.setItem('Cognome', result.cognome);
                        localStorage.setItem('Token', result.mytoken);
                        if (result.ruolo === 'Ristorante') {
                            this.router.navigate(['/dashboard/v3']).then(() => {
                                window.location.reload();
                            });
                        } else {
                            this.router.navigate(['/dashboard/v3']).then(() => {
                                window.location.reload();
                            });
                        }
                    } else {
                        alert('Login inesistente o password errata. Ridigita correttamente i tuoi dati!');
                    }
                }, error => console.error(error));


        }
    }

    ngOnInit() {
        localStorage.setItem('Token', '');
    }



}


