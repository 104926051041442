import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';


import { TrendAppApiService } from '../../services/trend-app-api.service';
import { Manutenzione } from '../../model/manutenzione';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from '../../model/global';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    ruolo = localStorage.getItem('Ruolo');
    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    myAppUrl: string;

    inAttesa: number;
    approvate: number;
    inLavorazione: number;
    confermate: number;
    public manutenzioni: Manutenzione[];
    public manutenzioniInAttesa: Manutenzione[];
    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(private http: HttpClient, public datepipe: DatePipe,private trendAppApiService: TrendAppApiService, public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, public injector: Injector) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.myAppUrl = GlobalVariable.BASE_API_URL;
        this.inAttesa = 0;
        this.approvate = 0;
        this.inLavorazione = 0;
        this.confermate = 0;


    }

    get user(): any {
        return localStorage.getItem('Nome') + ' ' + localStorage.getItem('Cognome');
    }

    ngOnInit() {
        this.isNavSearchVisible = false;

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });
        this.manutenzioniInAttesa=[];
        this.http
            .get<Manutenzione[]>(this.myAppUrl + 'MNT_Manutenzione?utnId=' + localStorage.getItem('UserId'), {
                headers: new HttpHeaders({
                    Authorization: 'Bearer ' + localStorage.getItem('Token'),
                })
            })



            .subscribe(
                result => {
                    this.manutenzioni = result;
                    this.manutenzioni.forEach(mnt => {
                        if (mnt.MntStato === 'In attesa') {
                            this.inAttesa++;
                            this.manutenzioniInAttesa.push(mnt);
                        }
                        if (mnt.MntStato === 'In lavorazione') {
                            this.inLavorazione++;
                        }
                        if (mnt.MntStato === 'Approvato') {
                            this.approvate++;
                        }
                        if (mnt.MntStato === 'Confermato') {
                            this.confermate++;
                        }
                    });
                },

                error => {
                    error => console.error(error)
					//this.router.navigate(['/login']).then(() => {});
                }
            );


    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }
}
