import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { fbind } from 'q';
import { Password } from '../../../model/password';
import { TrendAppApiService } from '../../../services/trend-app-api.service';

@Component({
    selector: 'app-changePassword',
    templateUrl: './changePassword.component.html',
    styleUrls: ['./changePassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {


    valForm: FormGroup;
    id: number;

    constructor(private http: HttpClient, private trendAppApiService: TrendAppApiService, public settings: SettingsService, fb: FormBuilder, private router: Router) {
        localStorage.setItem('Logged', 'false');

        const newPassword1 = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        const certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(newPassword1)]);


        this.valForm = fb.group({
            login: [null, Validators.compose([Validators.required])],
            oldPassword: [null, Validators.required],
			newPassword1: [null, Validators.required],
			newPassword2: [null, Validators.required] //certainPassword
        });

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {



            const password: Password = {
                login: this.valForm.controls.login.value,
                oldPassword: this.valForm.controls.oldPassword.value,
                newPassword1: this.valForm.controls.newPassword1.value,
                newPassword2: this.valForm.controls.newPassword2.value
              };


            this.id = 0;




            this.trendAppApiService.setPassword(password)
              .subscribe(result => {
                        this.router.navigate(['/login']).then(() => {
                       window.location.reload();
                     });

              }, error => console.error(error));

        }
    }

    ngOnInit() {

    }



}


