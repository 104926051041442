import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadDownloadService } from './services/upload-download.service';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { DataTablesModule } from 'angular-datatables';

import { TrendAppApiService } from './services/trend-app-api.service';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeUk from '@angular/common/locales/en-GB';
// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';


registerLocaleData(localeIt);
//registerLocaleData(localeUk);

@NgModule({
    declarations: [
        AppComponent

    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        DataTablesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],

    providers: [
        { provide: LOCALE_ID, useValue: 'it-IT' },
        //{ provide: LOCALE_ID, useValue: 'en-GB' },
         DatePipe, DecimalPipe, CurrencyPipe, TrendAppApiService,UploadDownloadService],
    bootstrap: [AppComponent]
})
export class AppModule { }
