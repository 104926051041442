import { Component, Inject } from '@angular/core';
import { Corrispettivo } from '../../../model/corrispettivo';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss']
})
export class WeatherComponent {
    public forecasts: WeatherForecast[];
    public cacheForecasts: WeatherForecast[];
    public summaries: any[];
    public baseUrl2: string;

    public corrispettivi: Corrispettivo[];
    public cacheCorrispettivi: Corrispettivo[];



    filterForeCasts(filterVal: any) {
        if (filterVal === '0') {
            this.forecasts = this.cacheForecasts;
        } else {
            this.forecasts = this.cacheForecasts.filter((item) => item.summary === filterVal);
        }
    }


    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

        this.baseUrl2 = 'https://localhost:44367/api/';
        this.fetchApi();
    }

    reload() {
        this.forecasts = null;
        this.corrispettivi = null;
        setTimeout(() => {
            this.fetchApi();
        }, 1000);
    }

    fetchApi() {
        this.http.get<any[]>(this.baseUrl2 + 'WeatherForecast/GetSummaries').subscribe(result => {
            this.summaries = result;
        }, error => console.error(error));

        this.http
            .get<WeatherForecast[]>(this.baseUrl2 + 'WeatherForecast')
            .subscribe(
                result => {
                    this.forecasts = result;
                    this.cacheForecasts = result;
                },
                error => console.error(error)
            );

        this.http
            .get<Corrispettivo[]>(this.baseUrl2 + 'CRR_Corrispettivo', { headers: new HttpHeaders({
                Authorization: localStorage.getItem('UserId'),
                'Content-Type': 'application/json',
              })})
            //.get<Corrispettivo[]>(this.baseUrl2 + 'CRR_Corrispettivo/GetCorrispettivi')
            .subscribe(
                result => {
                    this.corrispettivi = result;
                    this.cacheCorrispettivi = result;
                },
                error => console.error(error)

            );
    }
}


interface WeatherForecast {
    dateFormatted: string;
    temperatureC: number;
    temperatureF: number;
    summary: string;
}
interface Summary {
    name: string;
}
