import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { Area } from "../model/area";
import { Utente } from "../model/utente";
import { Societa } from "../model/societa";
import { Negozio } from "../model/negozio";
import { Manutenzione } from "../model/manutenzione";
import { Librocassa } from "../model/librocassa";
import { Corrispettivo } from "../model/corrispettivo";
import { Budget } from "../model/budget";
import { Consuntivo } from "../model/consuntivo";
import { Recapdata } from "../model/recapdata";
import { Token } from "../model/token";
import { Password } from "../model/password";
import { File } from "../model/file";
import { GlobalVariable } from "../model/global";
import { Evento } from "../model/evento";

@Injectable({
  providedIn: "root",
})
export class TrendAppApiService {
  myAppUrl: string;
  myApiAreaUrl: string;
  myApiUtenteUrl: string;
  myApiSocietaUrl: string;
  myApiFileUrl: string;
  myApiNegozioUrl: string;
  myApiManutenzioneUrl: string;
  myApiLibrocassaUrl: string;
  myApiCorrispettivoUrl: string;
  myApiBudgetUrl: string;
  myApiConsuntivoUrl: string;
  myApiRecapDataUrl: string;
  myApiTokenDataUrl: string;
  myApiPasswordDataUrl: string;
  myApiDatatableApiUrl: string;
  myApiEventiUrl: string;
  myApiUploadUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = GlobalVariable.BASE_API_URL; // 'http://www.betatest.cloud/trendappapi/api/';
    /*environment.appUrl"";*/
    /* http://www.betatest.cloud/trendappapi/api/ */
    /* https://localhost:44367/api/ */
    this.myApiAreaUrl = "ARE_Area/";
    this.myApiUtenteUrl = "UTN_Utente/";
    this.myApiFileUrl = "FIL_File/";
    this.myApiSocietaUrl = "SCT_Societa/";
    this.myApiNegozioUrl = "NGZ_Negozio/";
    this.myApiManutenzioneUrl = "MNT_Manutenzione/";
    this.myApiLibrocassaUrl = "LBC_Libro_Cassa/";
    this.myApiCorrispettivoUrl = "CRR_Corrispettivo/";
    this.myApiBudgetUrl = "BDG_Budget/";
    this.myApiConsuntivoUrl = "CNS_Consuntivo/";
    this.myApiRecapDataUrl = "RecapData/";
    this.myApiTokenDataUrl = "Token/";
    this.myApiPasswordDataUrl = "Password/";
    this.myApiDatatableApiUrl = "DatatableApi/";

    this.myApiEventiUrl = "Eventi/";
    this.myApiUploadUrl = "Upload/";
  }

  //#region Varie
  getToken(token): Observable<Token> {
    return this.http
      .post<Token>(
        this.myAppUrl + this.myApiTokenDataUrl,
        JSON.stringify(token),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  setPassword(password): Observable<Password> {
    return this.http
      .post<Password>(
        this.myAppUrl + this.myApiPasswordDataUrl,
        JSON.stringify(password),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getAllEmployees(): Observable<Utente[]> {
    return this.http.get<Utente[]>(this.myAppUrl + this.myApiAreaUrl);
  }

  getAllEmployeesWithPaging(dtParams: any): Observable<any> {
    return this.http.put(this.myAppUrl + this.myApiAreaUrl, dtParams);
  }

  getRecapData(type: number): Observable<Recapdata[]> {
    return this.http
      .get<Recapdata[]>(this.myAppUrl + this.myApiRecapDataUrl + type)
      .pipe(retry(1));
  }

  errorHandler(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  //#endregion

  //#region Area

  getAreas(): Observable<Area[]> {
    return this.http
      .get<Area[]>(this.myAppUrl + this.myApiAreaUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getArea(areaId: number): Observable<Area> {
    return this.http
      .get<Area>(this.myAppUrl + this.myApiAreaUrl + areaId)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  saveArea(area): Observable<Area> {
    return this.http
      .post<Area>(
        this.myAppUrl + this.myApiAreaUrl,
        JSON.stringify(area),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateArea(areaId: number, area): Observable<Area> {
    return this.http
      .put<Area>(
        this.myAppUrl + this.myApiAreaUrl + areaId,
        JSON.stringify(area),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteArea(areaId: number): Observable<Area> {
    return this.http
      .delete<Area>(this.myAppUrl + this.myApiAreaUrl + areaId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Utenti

  getUtenti(): Observable<Utente[]> {
    return this.http
      .get<Utente[]>(this.myAppUrl + this.myApiUtenteUrl)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getUtente(utenteId: number): Observable<Utente> {
    return this.http
      .get<Utente>(this.myAppUrl + this.myApiUtenteUrl + utenteId)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  saveUtente(utente): Observable<Utente> {
    return this.http
      .post<Utente>(
        this.myAppUrl + this.myApiUtenteUrl,
        JSON.stringify(utente),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateUtente(utenteId: number, utente): Observable<Utente> {
    return this.http
      .put<Utente>(
        this.myAppUrl + this.myApiUtenteUrl + utenteId,
        JSON.stringify(utente),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteUtente(utenteId: number): Observable<Utente> {
    return this.http
      .delete<Utente>(this.myAppUrl + this.myApiUtenteUrl + utenteId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Societa

  getSocietas(): Observable<Societa[]> {
    return this.http
      .get<Societa[]>(this.myAppUrl + this.myApiSocietaUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getSocieta(societaId: number): Observable<Societa> {
    return this.http
      .get<Societa>(this.myAppUrl + this.myApiSocietaUrl + societaId)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  saveSocieta(societa): Observable<Societa> {
    return this.http
      .post<Societa>(
        this.myAppUrl + this.myApiSocietaUrl,
        JSON.stringify(societa),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateSocieta(societaId: number, societa): Observable<Societa> {
    return this.http
      .put<Societa>(
        this.myAppUrl + this.myApiSocietaUrl + societaId,
        JSON.stringify(societa),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteSocieta(societaId: number): Observable<Societa> {
    return this.http
      .delete<Societa>(this.myAppUrl + this.myApiSocietaUrl + societaId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Negozio

  getNegozi(): Observable<Negozio[]> {
    return this.http
      .get<Negozio[]>(this.myAppUrl + this.myApiNegozioUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getNegozio(negozioId: number): Observable<Negozio> {
    return this.http
      .get<Negozio>(this.myAppUrl + this.myApiNegozioUrl + negozioId)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  saveNegozio(negozio): Observable<Negozio> {
    return this.http
      .post<Negozio>(
        this.myAppUrl + this.myApiNegozioUrl,
        JSON.stringify(negozio),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateNegozio(negozioId: number, negozio): Observable<Negozio> {
    return this.http
      .put<Negozio>(
        this.myAppUrl + this.myApiNegozioUrl + negozioId,
        JSON.stringify(negozio),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteNegozio(negozioId: number): Observable<Negozio> {
    return this.http
      .delete<Negozio>(this.myAppUrl + this.myApiNegozioUrl + negozioId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Manutenzione

  getManutenzioni(): Observable<Manutenzione[]> {
    return this.http
      .get<Manutenzione[]>(this.myAppUrl + this.myApiManutenzioneUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getManutenzione(manutenzioneId: number): Observable<Manutenzione> {
    return this.http
      .get<Manutenzione>(
        this.myAppUrl + this.myApiManutenzioneUrl + manutenzioneId
      )
      .pipe(retry(1));
  }

  saveManutenzione(manutenzione): Observable<Manutenzione> {
    return this.http
      .post<Manutenzione>(
        this.myAppUrl + this.myApiManutenzioneUrl,
        JSON.stringify(manutenzione),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateManutenzione(
    manutenzioneId: number,
    manutenzione
  ): Observable<Manutenzione> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Manutenzione>(
        this.myAppUrl + this.myApiManutenzioneUrl + manutenzioneId,
        JSON.stringify(manutenzione),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteManutenzione(manutenzioneId: number): Observable<Manutenzione> {
    return this.http
      .delete<Manutenzione>(
        this.myAppUrl + this.myApiManutenzioneUrl + manutenzioneId
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region LibriCassa
  getLibricassa(): Observable<Librocassa[]> {
    return this.http
      .get<Librocassa[]>(this.myAppUrl + this.myApiLibrocassaUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getLibrocassa(librocassaId: number): Observable<Librocassa> {
    return this.http
      .get<Librocassa>(this.myAppUrl + this.myApiLibrocassaUrl + librocassaId)
      .pipe(retry(1));
  }

  saveLibrocassa(librocassa): Observable<Librocassa> {
    return this.http
      .post<Librocassa>(
        this.myAppUrl + this.myApiLibrocassaUrl,
        JSON.stringify(librocassa),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateLibrocassa(librocassaId: number, librocassa): Observable<Librocassa> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Librocassa>(
        this.myAppUrl + this.myApiLibrocassaUrl + librocassaId,
        JSON.stringify(librocassa),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteLibrocassa(librocassaId: number): Observable<Librocassa> {
    return this.http
      .delete<Librocassa>(
        this.myAppUrl + this.myApiLibrocassaUrl + librocassaId
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Corrispettivi
  getCorrispettivi(): Observable<Corrispettivo[]> {
    return this.http
      .get<Corrispettivo[]>(this.myAppUrl + this.myApiCorrispettivoUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getCorrispettivo(corrispettivoId: number): Observable<Corrispettivo> {
    return this.http
      .get<Corrispettivo>(
        this.myAppUrl + this.myApiCorrispettivoUrl + corrispettivoId,
        {
          headers: new HttpHeaders({
            Authorization: "Bearer " + localStorage.getItem("Token"),
          }),
        }
      )
      .pipe(retry(1));
  }

  saveCorrispettivo(corrispettivo): Observable<Corrispettivo> {
    return this.http
      .post<Corrispettivo>(
        this.myAppUrl + this.myApiCorrispettivoUrl,
        JSON.stringify(corrispettivo),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateCorrispettivo(
    corrispettivoId: number,
    corrispettivo
  ): Observable<Corrispettivo> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Corrispettivo>(
        this.myAppUrl + this.myApiCorrispettivoUrl + corrispettivoId,
        JSON.stringify(corrispettivo),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteCorrispettivo(corrispettivoId: number): Observable<Corrispettivo> {
    return this.http
      .delete<Corrispettivo>(
        this.myAppUrl + this.myApiCorrispettivoUrl + corrispettivoId
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Consuntivi

  getConsuntivi(): Observable<Consuntivo[]> {
    return this.http
      .get<Consuntivo[]>(this.myAppUrl + this.myApiConsuntivoUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getConsuntivo(consuntivoId: number): Observable<Consuntivo> {
    return this.http
      .get<Consuntivo>(this.myAppUrl + this.myApiConsuntivoUrl + consuntivoId)
      .pipe(retry(1));
  }

  saveConsuntivo(consuntivo): Observable<Consuntivo> {
    return this.http
      .post<Consuntivo>(
        this.myAppUrl + this.myApiConsuntivoUrl,
        JSON.stringify(consuntivo),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateConsuntivo(consuntivoId: number, consuntivo): Observable<Consuntivo> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Consuntivo>(
        this.myAppUrl + this.myApiConsuntivoUrl + consuntivoId,
        JSON.stringify(consuntivo),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteConsuntivo(consuntivoId: number): Observable<Consuntivo> {
    return this.http
      .delete<Consuntivo>(
        this.myAppUrl + this.myApiConsuntivoUrl + consuntivoId
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Budget

  getBudgets(): Observable<Budget[]> {
    return this.http
      .get<Budget[]>(this.myAppUrl + this.myApiBudgetUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getBudget(budgetId: number): Observable<Budget> {
    return this.http
      .get<Budget>(this.myAppUrl + this.myApiBudgetUrl + budgetId)
      .pipe(retry(1));
  }

  saveBudget(budget): Observable<Budget> {
    return this.http
      .post<Budget>(
        this.myAppUrl + this.myApiBudgetUrl,
        JSON.stringify(budget),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateBudget(budgetId: number, budget): Observable<Budget> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Budget>(
        this.myAppUrl + this.myApiBudgetUrl + budgetId,
        JSON.stringify(budget),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteBudget(budgetId: number): Observable<Budget> {
    return this.http
      .delete<Budget>(this.myAppUrl + this.myApiBudgetUrl + budgetId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region File

  getFiles(): Observable<File[]> {
    return this.http
      .get<File[]>(this.myAppUrl + this.myApiFileUrl, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("UserId"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  saveFile(file): Observable<File> {
    return this.http
      .post<File>(
        this.myAppUrl + this.myApiFileUrl,
        JSON.stringify(file),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteFile(filId: number): Observable<File> {
    return this.http
      .delete<File>(this.myAppUrl + this.myApiFileUrl + filId)
      .pipe(retry(1), catchError(this.errorHandler));
  }

  //#endregion

  //#region Eventi

  getEventi(): Observable<Evento[]> {
    return this.http
      .get<Evento[]>(this.myAppUrl + this.myApiEventiUrl, {
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("Token"),
          "Content-Type": "application/json",
        }),
      })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getEvento(eventoId: number): Observable<Evento> {
    return this.http
      .get<Evento>(this.myAppUrl + this.myApiEventiUrl + eventoId)
      .pipe(retry(1));
  }

  saveEvento(evento): Observable<Evento> {
    return this.http
      .post<Evento>(
        this.myAppUrl + this.myApiEventiUrl,
        JSON.stringify(evento),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  updateEvento(eventoId: number, evento): Observable<Evento> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .put<Evento>(
        this.myAppUrl + this.myApiEventiUrl + eventoId,
        JSON.stringify(evento),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  deleteEventi(eventoId: number): Observable<Evento> {
    return this.http
      .delete<Evento>(this.myAppUrl + this.myApiEventiUrl + eventoId)
      .pipe(retry(1), catchError(this.errorHandler));
  }
  //#endregion

  //#region Upload

  UndoFile(eventoId: number): Observable<boolean> {
    console.warn(eventoId);

    return this.http
      .get<boolean>(
        this.myAppUrl + this.myApiUploadUrl + "UndoFile/" + eventoId
      )
      .pipe(retry(1));
  }

  DoFile(eventoId: number): Observable<boolean> {
    console.warn(eventoId);
    return this.http
      .get<boolean>(this.myAppUrl + this.myApiUploadUrl + "DoFile/" + eventoId)
      .pipe(retry(1));
  }

  DownloadFile(eventoId: number): Observable<Blob> {
    return this.http.get(this.myAppUrl + this.myApiUploadUrl + "DownloadFile/" + eventoId, {
      responseType: 'blob'
    }).pipe(retry(1));
  }

  //#endregion
}
