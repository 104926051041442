export const GlobalVariable = Object.freeze({
 BASE_API_URL: "https://tastelab.euei.it/trendappapi/api/", // da usare in produzione ip nuovo con porta
 //  BASE_API_URL: 'https://localhost:44367/api/', // da usare in locale




  //BASE_API_URL: 'http://www.betatest.cloud/trendappapi/api/',

  //BASE_API_URL: 'http://localhost/trendappApi/api/', // da usare in produzione localhost
  //BASE_API_URL: 'http://tastelab.euei.it/trendappapi/api/',
  //BASE_API_URL: 'http://52.136.209.249/trendappapi/api/',
  // BASE_API_URL: 'http://40.74.22.157/trendappapi/api/', // da usare in produzione ip nuovo
  //BASE_API_URL: 'http://tastelab.euei.it:8887/trendappapi/api/', // da usare in produzione ip nuovo con porta

  //BASE_API_URL: 'https://tastelab.euei.it/trendappapi/api/',
  //... more of your variables
});

