import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>,
        amministratore?: boolean,
        ristorante?: boolean,
        areaManager?: boolean,
        partner?:boolean
    }>) {


         items.forEach((item) => {

             const ruolo = localStorage.getItem('Ruolo');
             if (
                (ruolo === 'Amministratore' && item.amministratore)
                ||
                (ruolo === 'Ristorante' && item.ristorante)
                ||
                (ruolo === 'Partner' && item.partner)
                ||
                (ruolo === 'Area Manager' && item.areaManager)
            ) {
                this.menuItems.push(item);
            }

        });

        let label='logout';
        if (localStorage.getItem('Logged') === 'true' || (localStorage.getItem('UserId') != null || localStorage.getItem('UserId') != '')) {
            label='Logout';
        }
        else {
            label='Login';
        }

        this.menuItems.push({
            text: label,
            heading: false,
            link: '/lock',     // internal route links
            elink: '',    // used only for external links
            target: '',   // anchor target="_blank|_self|_parent|_top|framename"
            icon: 'icon-lock',
            alert: '',
            submenu: null,
            amministratore: true,
            ristorante: true,
            areaManager: true,
            partner: true
          });
    }

    getMenu() {
        return this.menuItems;
    }

}
