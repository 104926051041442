
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};


const Dashboard = {
    text: 'Dashboard',
    link: '/dashboard',
    icon: 'icon-speedometer',
    submenu: [
        {
            text: 'Dashbord v1',
            link: '/dashboard/v1'
        },
        {
            text: 'Dashbord v2',
            link: '/dashboard/v2'
        },
        {
            text: 'Dashbord v3',
            link: '/dashboard/v3'
        }
    ]
};

const Widgets = {
    text: 'Widgets',
    link: '/widgets',
    icon: 'icon-grid'
};

const trendApp = {
    text: 'TrendApp',
    link: '/trendApp',
    icon: 'icon-grid'
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        },
        {
            text: 'Interaction',
            link: '/elements/interaction'
        },
        {
            text: 'Notification',
            link: '/elements/notification'
        },
        {
            text: 'SweetAlert',
            link: '/elements/sweetalert'
        },
        {
            text: 'Spinners',
            link: '/elements/spinners'
        },
        {
            text: 'Dropdown',
            link: '/elements/dropdown'
        },
        {
            text: 'Nav Tree',
            link: '/elements/navtree'
        },
        {
            text: 'Sortable',
            link: '/elements/sortable'
        },
        {
            text: 'Grid',
            link: '/elements/grid'
        },
        {
            text: 'Grid Masonry',
            link: '/elements/gridmasonry'
        },
        {
            text: 'Typography',
            link: '/elements/typography'
        },
        {
            text: 'Font Icons',
            link: '/elements/iconsfont'
        },
        {
            text: 'Weahter Icons',
            link: '/elements/iconsweather'
        },
        {
            text: 'Colors',
            link: '/elements/colors'
        },
        {
            text: 'Infinite Scroll',
            link: '/elements/infinitescroll'
        }
    ]
};


const Operativita = {
    text: 'Amministrazione',
    link: '/elements',
    icon: 'icon-notebook',
    ristorante: true,
    areaManager: true,
    partner: true,
    amministratore: true,
    submenu: [
        {
            text: 'Corrispettivi',
            icon: 'icon-calendar',
            link: '/operativita/corrispettivi'
        },
        {
            text: 'Libro cassa',
            icon: 'icon-calculator',
            link: '/operativita/libricassa'
        },
        {
            text: 'Manutenzioni',
            icon: 'icon-book-open',
            link: '/operativita/manutenzioni'
        }
    ]
};


const BusinessIntelligence = {
    text: 'Business Intelligence',
    link: '/elements',
    icon: 'icon-target',
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: true,
    submenu: [
        {
            text: 'Consuntivi',
            icon: 'fas fa-money-bill',
            link: '/businessIntelligence/consuntivi'
        },
        {
            text: 'Budget',
            icon: 'fas fa-money-check',
            link: '/businessIntelligence/budget'
        },
        {
            text: 'Grafici per negozi',
            icon: 'fas fa-file-excel',
            link: '/businessIntelligence/datiNegozi'
          },
          {
              text: 'Grafici per società',
              icon: 'fas fa-globe',
              link: '/businessIntelligence/datiSocieta'
          }
/*
        ,

        {
            text: 'Budget Excel',
            icon: 'fas fa-file-excel',
            link: '/businessIntelligence/budgetexcel'
        },*/
    ]
};

const DataImport = {
  text: 'Data Import',
  link: '/elements',
  icon: 'icon-folder',
  ristorante: false,
  areaManager: false,
  partner: false,
  amministratore: true,
  submenu: [
/*       {
          text: 'Download',
          icon: 'fas fa-download',
          link: '/dataImport/download'
      }, */
      {
          text: 'Upload',
          icon: 'fas fa-upload',
          link: '/dataImport/upload'
      },
      {
          text: 'Registro',
          icon: 'fas fa-registered',
          link: '/dataImport/registroEventi'
        }
  ]
};

const Anagrafiche = {
    text: 'Anagrafiche',
    link: '/elements',
    icon: 'icon-user',
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: true,
    submenu: [
        {
            text: 'Ristoranti',
            link: '/anagrafiche/negozi'
        },
        {
            text: 'Società',
            link: '/anagrafiche/societa'
        },
        {
            text: 'Brand',
            link: '/anagrafiche/aree'
        },
        {
            text: 'Utenti',
            link: '/anagrafiche/utenti'
        },
    ]
};
const Forms = {
    text: 'Forms',
    link: '/forms',
    icon: 'icon-note',
    submenu: [
        {
            text: 'Standard',
            link: '/forms/standard'
        },
        {
            text: 'Extended',
            link: '/forms/extended'
        },
        {
            text: 'Validation',
            link: '/forms/validation'
        },
        {
            text: 'Upload',
            link: '/forms/upload'
        },
        {
            text: 'Image Crop',
            link: '/forms/cropper'
        }
    ]
};

const Charts = {
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: false,

    text: 'Charts',
    link: '/charts',
    icon: 'icon-graph',
    submenu: [
        {
            text: 'Flot',
            link: '/charts/flot'
        },
        {
            text: 'Radial',
            link: '/charts/radial'
        },
        {
            text: 'ChartJS',
            link: '/charts/chartjs'
        }
    ]
};

const Tables = {
    text: 'Tables',
    link: '/tables',
    icon: 'icon-grid',
    amministratore: false,
    ristorante: false,
    submenu: [
        {
            text: 'Standard',
            link: '/tables/standard'
        },
        {
            text: 'Extended',
            link: '/tables/extended'
        },
        {
            text: 'Data-Tables',
            link: '/tables/datatable'
        },
        {
            text: 'Angular Grid',
            link: '/tables/aggrid'
        },
        {
            text: 'NGxDatatables',
            link: '/tables/ngxdatatable'
        }
    ]
};

const Maps = {
    text: 'Maps',
    link: '/maps',
    icon: 'icon-map',
    submenu: [
        {
            text: 'Google',
            link: '/maps/google'
        },
        {
            text: 'Vector',
            link: '/maps/vector'
        }
    ]
};

const Pages = {
    text: 'Pages',
    link: '/pages',
    icon: 'icon-doc',
    submenu: [
        {
            text: 'Login',
            link: '/login'
        },
        {
            text: 'Register',
            link: '/register'
        },
        {
            text: 'ChangePassword',
            link: '/changePassword'
        },
		{
            text: 'Recover',
            link: '/recover'
        },
        {
            text: 'Lock',
            link: '/lock'
        },
        {
            text: '404',
            link: '/404'
        },
        {
            text: '500',
            link: '/500'
        },
        {
            text: 'Maintenance',
            link: '/maintenance'
        }
    ]
};

const Blog = {
    text: 'Blog',
    link: '/blog',
    icon: 'icon-notebook',
    submenu: [
        {
            text: 'List',
            link: '/blog/list'
        },
        {
            text: 'Post',
            link: '/blog/post'
        },
        {
            text: 'Articles',
            link: '/blog/articles'
        },
        {
            text: 'Article View',
            link: '/blog/articleview'
        }
    ]
};

const Ecommerce = {
    text: 'Ecommerce',
    link: '/ecommerce',
    icon: 'icon-basket-loaded',
    submenu: [
        {
            text: 'Orders',
            link: '/ecommerce/orders'
        },
        {
            text: 'Order View',
            link: '/ecommerce/orderview'
        },
        {
            text: 'Products',
            link: '/ecommerce/products'
        },
        {
            text: 'Product View',
            link: '/ecommerce/productview'
        },
        {
            text: 'Checkout',
            link: '/ecommerce/checkout'
        }
    ]
};


const Extras = {
    text: 'Informazioni',
    link: '/extras',
    icon: 'icon-cup',
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: false,
    submenu: [
        {
            text: 'Contatti',
            link: '/extras/contacts',
			ristorante: false,
      areaManager: false,
      partner: false,
			amministratore: false
        },

        {
            text: 'Faq',
            link: '/extras/faq',
			ristorante: true,
      areaManager: true,
      partner: true,
			amministratore: true
        },
        {
            text: 'Help',
            link: '/extras/helpcenter',
			ristorante: true,
      areaManager: true,
      partner: true,
			amministratore: true
        }

    ]
};

const Api = {
    text: 'Api Demo',
    link: '/api/weather',
    icon: 'icon-target',
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: false
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const headingTasteLab = {
    text: 'TasteLab',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

const DashboardTasteLabAmministratore = {
    text: 'Dashboard',
    link: '/dashboard/v3',
    icon: 'icon-graph',
    ristorante: false,
    areaManager: true,
    partner: true,
    amministratore: true
};

const DashboardTasteLabRistorante = {
    text: 'Dashboard',
    link: '/dashboard/v3',
    icon: 'icon-graph',
    ristorante: true,
    areaManager: false,
    partner: false,
    amministratore: false
};


const Budget = {
    text: 'Budget',
    link: '/budget',
    icon: 'icon-chart',
    ristorante: false,
    areaManager: false,
    partner: false,
    amministratore: false
};

/*
const Corrispettivi = {
    text: 'Corrispettivi',
    link: '/corrispettivi',
    icon: 'icon-calendar'
};

const Librocassa = {
    text: 'Libro cassa',
    link: '/librocassa',
    icon: 'icon-calculator'
};
*/
const Manutenzioni = {
    text: 'Manutenzioni',
    link: '/manutenzioni',
    icon: 'icon-book-open',
    ristorante: true,
    areaManager: true,
    partner: true,
    amministratore: true
};
const Documenti = {
    text: 'Documenti',
    link: '/extras/filemanager',
    icon: 'icon-paper-clip',
    ristorante: true,
    areaManager: true,
    partner: true,
    amministratore: true
};
export const menu = [

    headingTasteLab,
    DashboardTasteLabAmministratore,
    DashboardTasteLabRistorante,
    Anagrafiche,
    Operativita,
	  BusinessIntelligence,
    DataImport,
    Budget,
    Documenti,
    Elements,
    headingMain,
    Home,
    Dashboard,
    Widgets,
    headingComponents,
    Forms,
    Charts,
    Tables,
    Maps,
    headingMore,
    Pages,
    Blog,
    Ecommerce,
    Extras,
    Api /* */
];
